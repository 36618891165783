// Override Bootstrap color system

$yellow: #fed136;
$blue: #309078;
$orange: #ff7d27;

// Override Bootstrap default state colors

// $primary: #000;

// Override Bootstrap yiq lightness value

$yiq-contrasted-threshold: 205;
