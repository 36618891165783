// Styling for the navbar
#mainNav {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: rgba(33, 37, 41, 1);
    .navbar-toggler {
        padding: 0.75rem;
        font-family: $headings-font-family;
        font-size: 0.75rem;
        color: $white;
        text-transform: uppercase;
        background-color: $primary;
        border: 0;
    }
    .navbar-nav {
        .nav-item {
            .nav-link {
                font-family: $headings-font-family;
                font-size: 0.95rem;
                font-weight: bold;
                color: $yellow;
                letter-spacing: $headings-letter-spacing;
                &.active,
                &:hover {
                    color: $white;
                }
            }
        }
    }
}

// Responsive styling for screen width equal to or greater than the large breakpoint
@include media-breakpoint-up(lg) {
    #mainNav {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border: none;
        background-color: transparent;
        transition: padding-top 0.3s ease-in-out,
            padding-bottom 0.3s ease-in-out, background-color 0.3s ease-in-out;
        // Styling for when the navbar shrinks on larger screens
        &.navbar-shrink {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            background-color: rgba(33, 37, 41, 0.5);
            .navbar-brand {
                font-size: 1.25em;
                svg,
                img {
                    height: 1.5rem;
                }
            }
        }
        .navbar-nav .nav-item {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.shop-button {
    a {
        color: white !important;
    }
    background-color: #be03fc;
    border-radius: 20%;
    box-shadow: 0px 0px 12px 6px rgb(0 0 0 / 50%);
    // -webkit-box-shadow: 0px 0px 12px 6px rgb(0 0 0 / 50%);
    transition: box-shadow 0.3s ease-in-out;
    transition: background-color 1s ease-in-out;
}

/* Transition to a bigger shadow on hover */
.shop-button:hover {
    background-color: #6d0091;
}
