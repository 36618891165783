// Button Styles

.btn-xl {
    padding: 1.25rem 2.5rem;
    font-family: $headings-font-family;
    font-size: 1.125rem;
    font-weight: $headings-font-weight;
}

.btn-social {
    font-size: 2rem;
    height: 2.5rem;
    width: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &#instagram {
        color: #e4405f;
    }

    &#facebook {
        color: #1877f2;
    }

    &#twitter {
        color: #1da1f2;
    }

    &#pinterest {
        color: #bd081c;
    }

    &#linkedin {
        color: #0077b5;
    }
}
