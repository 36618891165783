// Styling for the portfolio section
$text-margin: 0.1rem;
$hover-margin: 0.5rem;
$transition-time: 0.3s;

#portfolio {
    .portfolio-item {
        display: block;
        position: relative;
        overflow: hidden;
        max-width: 530px;
        margin: auto auto 1rem;
        .caption {
            display: flex;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            .caption-content {
                color: $white;
                margin: auto auto $text-margin $text-margin;
                h2 {
                    @include Roboto(4, 4);
                    margin: 0;
                    font-weight: 900;
                }
            }
        }
        @media (min-width: 992px) {
            max-width: none;
            margin: 0;
            .caption {
                transition: clip-path $transition-time ease-in-out,
                    background-color $transition-time;
                clip-path: inset(0px);
                .caption-content {
                    transition: opacity $transition-time,
                        margin $transition-time ease-in-out;
                }
            }
            img {
                transition: clip-path $transition-time ease-in-out;
                clip-path: inset(0px);
            }
            &:hover {
                img {
                    clip-path: inset($hover-margin);
                }
                .caption {
                    background-color: fade-out($orange, 0.1);
                    clip-path: inset($hover-margin);
                }
                .caption-content {
                    margin: auto auto #{$hover-margin + $text-margin} #{$hover-margin +
                        $text-margin};
                }
            }
        }
    }
}

.portfolio-modal {
    .modal-dialog {
        margin: 1rem;
        max-width: 100vw;
    }
    .modal-content {
        padding-top: 6rem;
        padding-bottom: 6rem;
        text-align: center;
        h2 {
            font-size: 3rem;
            line-height: 3rem;
        }
        p.item-intro {
            font-style: italic;
            margin-bottom: 2rem;
            font-family: $subheadings-font-family;
        }
        p {
            font-size: 1.2rem;
        }
        p:last-child {
            margin-bottom: 2rem;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
        }
        img {
            margin-bottom: 2rem;
        }
        blockquote {
            p {
                font-size: 1.4rem;
                font-style: italic;
            }
            footer {
                font-size: 1.4rem;
            }
        }
    }
    .close-modal {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        background-color: transparent;
        &:hover {
            opacity: 0.3;
        }
    }
}

.prints-portfolio {
    background-color: #2c2e35;
    color: white;
}

.cards-portfolio {
    background-color: rgb(73, 18, 96);
    color: white;
}
