// Styling for the masthead
header.masthead {
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;
    color: $white;
    background-image: url("../img/header-bg.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    .btn {
        letter-spacing: 4px;
    }

    img {
        width: 80%;
    }
}

@include media-breakpoint-up(md) {
    header.masthead {
        padding-top: 18rem;
        padding-bottom: 18rem;
    }
}

@include media-breakpoint-up(xl) {
    header.masthead {
        // height: 100vh;
        display: flex;
        align-items: center;
    }
}

// Styling for the masthead
header.masthead-small {
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;
    color: $white;
    background-image: url("../img/header-bg.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    .btn {
        letter-spacing: 4px;
    }

    img {
        width: 80%;
    }
}
