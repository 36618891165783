// Styling for the contact section
section#contact {
    background-color: $orange;
    background-repeat: no-repeat;
    background-position: center;
    .section-heading {
        color: $white;
    }
    .section-subheading {
        color: $white;
        font-size: 1.2rem;
    }
    form#contactForm {
        .form-group {
            margin-bottom: $grid-gutter-width;
            input,
            textarea {
                padding: 1.25rem;
            }
            input.form-control {
                height: auto;
            }
        }
        .form-group-textarea {
            height: 100%;
            textarea {
                height: 100%;
                min-height: 10rem;
            }
        }
        p.help-block {
            margin: 0;
        }
        .form-control:focus {
            border-color: $primary;
            box-shadow: none;
        }
        ::-webkit-input-placeholder {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            color: $gray-400;
        }
        :-moz-placeholder {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            color: $gray-400;
        }
        ::-moz-placeholder {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            color: $gray-400;
        }
        :-ms-input-placeholder {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            color: $gray-400;
        }

        .honeypot-field {
            display: none;
        }
    }

    // button {
    //     background-color: #309078;
    //     border-color: #309078;
    // }
}
