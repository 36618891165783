$transition: all 1s cubic-bezier(0.21, 1, 0.84, 1.01);

section.slice-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    color: $white;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;

    .slice-heading {
        letter-spacing: 0.5rem;
        font-size: 3rem;
        font-weight: bold;
        line-height: 3rem;
        font-family: $headings-font-family;
        transition: $transition;
    }

    .slice-link {
        font-size: 1.5rem;
        letter-spacing: 0.3rem;
        transition: $transition;
    }

    @media (min-width: 992px) {
        &:hover {
            .slice-heading {
                transform: scale(1.05);
            }

            .slice-link {
                transform: scale(1.2);
                translate: 0 0.5rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    section.slice-section {
        padding-top: 12.5rem;
        padding-bottom: 12.5rem;
        .slice-heading {
            font-size: 4.5rem;
            line-height: 3rem;
        }
        .slice-link {
            font-size: 2.5rem;
        }
    }
}

#slice-commissions {
    background-image: url("../img/slices/commissions.jpg");
}

#slice-prints {
    background-image: url("../img/slices/prints.jpg");
}

#slice-cards {
    background-image: url("../img/slices/cards.jpg");
}

#slice-design {
    background-image: url("../img/slices/design.jpg");
}

#slice-teaching {
    background-image: url("../img/slices/teaching.jpg");
}

#slice-visit {
    background-image: url("../img/slices/visit.jpg");
}
