@use "sass:math";

// Override Bootstrap typography variables

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

@function calculateTypeOffset($lh, $fontSize, $descenderHeightScale) {
    $lineHeightScale: math.div($lh, $fontSize);
    @return math.div(($lineHeightScale - 1), 2 + $descenderHeightScale);
}

$baseFontSize: 8;
$gridRowHeight: 4;

@mixin basekick(
    $typeSizeModifier,
    $typeRowSpan,
    $descenderHeightScale,
    $capHeight
) {
    $fontSize: $typeSizeModifier * $baseFontSize;
    $lineHeight: $typeRowSpan * $gridRowHeight;
    $typeOffset: calculateTypeOffset(
        $lineHeight,
        $fontSize,
        $descenderHeightScale
    );
    $topSpace: $lineHeight - $capHeight * $fontSize;
    $heightCorrection: 0;

    @if $topSpace > $gridRowHeight {
        $heightCorrection: $topSpace - ($topSpace % $gridRowHeight);
    }

    $preventCollapse: 1;

    font-size: #{$fontSize}px;
    line-height: #{$lineHeight}px;
    transform: translateY(#{$typeOffset}em);
    padding-top: #{$preventCollapse}px;

    &::before {
        content: "";
        margin-top: #{- ($heightCorrection + $preventCollapse)}px;
        display: block;
        height: 0;
    }
}

@mixin Roboto($typeSizeModifier, $typeRowSpan) {
    $descenderHeightScale: 0.15;
    $capHeight: 0.75;

    @include basekick(
        $typeSizeModifier,
        $typeRowSpan,
        $descenderHeightScale,
        $capHeight
    );
    font-family: "Roboto Condensed";
}

$font-family-base: "Roboto Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$subheadings-font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-script: "Roboto Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-weight: 700;
$headings-letter-spacing: 0.0625em;
