// Global styling for this template
body {
    overflow-x: hidden;
}

p {
    line-height: 1.75;
}

.page-section {
    padding: 3rem 0;
    h2.section-heading {
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }
    .section-subheading {
        h3 {
            font-size: 1.3rem;
            font-weight: lighter;
            letter-spacing: 0.1rem;
            font-family: $subheadings-font-family;
        }

        h3:last-child {
            margin-bottom: 4rem;
        }
    }
}

@include media-breakpoint-up(md) {
    section {
        padding: 9rem 0;
    }
}

// Highlight color customization

::-moz-selection {
    background: $primary;
    text-shadow: none;
}

::selection {
    background: $primary;
    text-shadow: none;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}
