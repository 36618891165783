#about {
    background-color: #2b2e37;
    color: $white;

    .about-images {
        padding-bottom: 1rem;

        figcaption {
            // color: white;
            font-size: 0.8rem;
        }
    }
}

#testimonials {
    color: white;
    background-color: rgb(78, 52, 126);
}

#testimonials-2 {
    background-color: rgb(120, 155, 63);
}

.testimonial {
    text-align: center;

    &.white {
        color: $white;
    }

    &.orange {
        color: $orange;
    }

    &.block {
        color: $black;
    }

    &.author {
        color: $yellow;

        p {
            margin-bottom: 0;
        }
    }
}
